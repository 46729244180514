import { Component } from 'react';

class ChangePasswordHandler extends Component {

  componentDidMount() {
    window.opener.location.replace('/club-artisee/my-page/');
    window.close();
  }

  render() {
    return null;
  }
}

export default ChangePasswordHandler;